export function exibirJSONFormatado(objeto, nivel = 0) {
    const espacos = '   '.repeat(nivel);
    let resultado = '{\n';
    for (let chave in objeto) {
        if (Array.isArray(objeto[chave]) && objeto[chave].length === 0) {
            resultado += `${espacos}"${chave}": [],\n`;
        } else if (typeof objeto[chave] === 'object' && objeto[chave] !== null) {
            resultado += `${espacos}"${chave}": {\n`;
            resultado += exibirJSONFormatado(objeto[chave], nivel + 1);
            resultado += `${espacos}},\n`;
        } else {
            resultado += `${espacos}"${chave}": ${JSON.stringify(objeto[chave])},\n`;
        }
    }
    resultado += '}';

    const indexUltimaVirgula = resultado.lastIndexOf(','); 
    return resultado.substring(0,indexUltimaVirgula) + resultado.substring(indexUltimaVirgula+1, resultado.length);
  }

  export const validarJson = (json) => {
    try {
        JSON.parse(json)
        return true;
    } catch (erro){
        return false;
    }
}