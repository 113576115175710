import { converterDataFormatadaParaISOBr, convertStringToDate } from '../../../../common/DataUtils';
import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";
import { SIGLA_CAMPO_DII, tiposNexoTecnico } from '../../constants';
import {
    isRespostaCamposGenericosNaoPermiteNexo, obterVinculosFiltradosPorTipoFiliacao, obterVinculosParaRelacaoNexoEpidemiologico
} from './utils';

export const verificarSeCamposPreenchidos = pericia => {
  const moduloNexo = pericia?.moduloNexoTecnico;

  return moduloNexo && (moduloNexo.existeNexoProfissional !== undefined
    || moduloNexo.existeNexoIndividual !== undefined
    || moduloNexo.existeNtep !== undefined);
}

export const validar = (pericia, getState) => {
  const moduloNexo = pericia.moduloNexoTecnico;

  const vinculos = pericia.vinculosPericia;
  
  // regra de negocio: filtrando vinculos que podem ser enviados ao SIBE
  const vinculosElegiveisParaEnvioAoSibe = vinculos?.filter(v => v.elegivelParaEnvioAoSibe);
  
  const der = converterDataFormatadaParaISOBr(pericia.dataReferencia);

  const existemVinculos = vinculosElegiveisParaEnvioAoSibe && vinculosElegiveisParaEnvioAoSibe.length > 0;
  const cidPrincipal = pericia.moduloCid?.cidPrincipal;
  const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
  const dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);
  const valorCampoNaoPermiteNexo = isRespostaCamposGenericosNaoPermiteNexo(pericia.valoresCamposGenericos);

  //regra de negocio: filtrando vinculos nexo por tipo de filiacao
  const vinculosFiltrados = obterVinculosFiltradosPorTipoFiliacao(vinculosElegiveisParaEnvioAoSibe, dii);

  //regra de negocio: vinculos para nexo epidemiologico precisam estar marcados para incapacidade laborativa
  const vinculosComIncapacidade = vinculosElegiveisParaEnvioAoSibe?.filter(v => v.possuiIncapacidade === 1);

  const { fatoresRiscoCid, batimentoCidCnae } = getState().modulos.moduloNexoTecnico;
  const existeFatoresRisco = fatoresRiscoCid && fatoresRiscoCid.length > 0;
  const exibeNexo = cidPrincipal && existemVinculos && valorCampoDii && !valorCampoNaoPermiteNexo;

  const vinculosNexoProfissional = exibeNexo && vinculosFiltrados;
  const vinculosNexoIndividual = exibeNexo && vinculosFiltrados;
  const vinculosNexoEpidemiologico = exibeNexo && obterVinculosParaRelacaoNexoEpidemiologico(vinculosComIncapacidade, batimentoCidCnae, der, dii);
  const existeBatimentoCidCnae = vinculosNexoEpidemiologico?.length > 0;

  const existemVinculosCompativeisNexoProfissional = vinculosNexoProfissional && vinculosNexoProfissional.length > 0;
  const existemVinculosCompativeisNexoIndividual = vinculosNexoIndividual && vinculosNexoIndividual.length > 0;
  const existemVinculosCompativeisNexoEpidemiologico = vinculosNexoEpidemiologico && vinculosNexoEpidemiologico.length > 0;

  const nexoProfissionalNaoSelecionado = !existeFatoresRisco || !moduloNexo?.existeNexoProfissional
    || !existemVinculosCompativeisNexoProfissional;
  const nexoIndividualNaoSelecionado = !existemVinculosCompativeisNexoIndividual
    || moduloNexo.existeNexoIndividual === 0; //NAO para nexo individual
  const exibeNexoEpidemiologico = nexoProfissionalNaoSelecionado
    && existeBatimentoCidCnae
    && nexoIndividualNaoSelecionado;

  let errosNexo = {};

  if (exibeNexo) {
    //com batimento fatores de risco e vinculos compativeis
    if (existeFatoresRisco && existemVinculosCompativeisNexoProfissional) {

      //validar nexo profissonal
      if (moduloNexo.existeNexoProfissional === undefined) {
        errosNexo = { ...errosNexo, erroRespostaNexoProfissionalObrigatoria: MSG_CAMPO_OBRIGATORIO };
      } else {
        if (moduloNexo.existeNexoProfissional === 1) {//SIM para nexo profissional

          if (!moduloNexo.fatoresRiscoProfissional || moduloNexo.fatoresRiscoProfissional.length === 0) {
            errosNexo = { ...errosNexo, erroFatoresRiscoObrigatorio: MSG_CAMPO_OBRIGATORIO };
          }
          if (moduloNexo.tipoDoencaNexoProfissional === undefined) {
            errosNexo = { ...errosNexo, erroTipoDoencaNexoProfissionalObrigatoria: MSG_CAMPO_OBRIGATORIO };
          }

          const vinculosNexoProfissional = vinculosElegiveisParaEnvioAoSibe.filter(v => v.tipoNexoTecnico === tiposNexoTecnico.PROFISSIONAL.id);
          if (!vinculosNexoProfissional || vinculosNexoProfissional.length === 0) {
            errosNexo = { ...errosNexo, erroVinculoNexoProfissionalObrigatorio: MSG_CAMPO_OBRIGATORIO };
          }

          if (!moduloNexo.fundamentacaoNexoProfissional || moduloNexo.fundamentacaoNexoProfissional.trim().length === 0) {
            errosNexo = { ...errosNexo, erroFundamentacaoNexoProfissionalObrigatoria: MSG_CAMPO_OBRIGATORIO };
          }

        } else if (!moduloNexo.justifNegacaoNexoProfissional || moduloNexo.justifNegacaoNexoProfissional.trim().length === 0) {//NAO para nexo profissional
          errosNexo = { ...errosNexo, erroJustifNegacaoNexoProfissionalObrigatoria: MSG_CAMPO_OBRIGATORIO };
        }
      }
    }

    if (nexoProfissionalNaoSelecionado && existemVinculosCompativeisNexoIndividual) {
      //validar nexo individual
      if (moduloNexo.existeNexoIndividual === undefined) {
        errosNexo = { ...errosNexo, erroRespostaNexoIndividualObrigatoria: MSG_CAMPO_OBRIGATORIO };
      } else {
        if (moduloNexo.existeNexoIndividual === 1) {//SIM para nexo individual

          if (moduloNexo.tipoNexoIndividual === undefined) {
            errosNexo = { ...errosNexo, erroTipoNexoIndividualObrigatorio: MSG_CAMPO_OBRIGATORIO };
          }
          const vinculosNexoIndividual = vinculosElegiveisParaEnvioAoSibe.filter(v => v.tipoNexoTecnico === tiposNexoTecnico.INDIVIDUAL.id);
          if (!vinculosNexoIndividual || vinculosNexoIndividual.length === 0) {
            errosNexo = { ...errosNexo, erroVinculoNexoIndividualObrigatorio: MSG_CAMPO_OBRIGATORIO };
          }

          if (!moduloNexo.fundamentacaoNexoIndividual || moduloNexo.fundamentacaoNexoIndividual.trim().length === 0) {
            errosNexo = { ...errosNexo, erroFundamentacaoNexoIndividualObrigatoria: MSG_CAMPO_OBRIGATORIO };
          }
        }
      }
    }
    if (exibeNexoEpidemiologico && existemVinculosCompativeisNexoEpidemiologico) {
      //validar nexo Epidemiologico
      vinculosNexoEpidemiologico.filter(v => v.possuiNexoTecnico === 0).forEach(v => {
        if (!v.descaracterizacaoNtep || !v.descaracterizacaoNtep.motivoNaoFixacaoNtep) {
          errosNexo = {
            ...errosNexo,
            [`erroMotivoNaoFixacaoNtepObrigatorio_vinculo_${v.id}`]: MSG_CAMPO_OBRIGATORIO
          };
        }
        if (!v.descaracterizacaoNtep
          || !v.descaracterizacaoNtep.fundamentacaoNaoFixacaoNtep
          || v.descaracterizacaoNtep.fundamentacaoNaoFixacaoNtep.trim().length === 0) {
          errosNexo = {
            ...errosNexo,
            [`erroFundamentacaoNaoFixacaoNtepObrigatoria_vinculo_${v.id}`]: MSG_CAMPO_OBRIGATORIO
          };
        }
      });
    }
    return {
      isValida: Object.values(errosNexo).length <= 0,
      ...errosNexo
    }
  } else {
    return {
      isValida: true
    }
  }

}