import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import {
  Redirect, Route, Router, Switch
} from "react-router-dom";

import { unStoreToken } from "../common/AuthManager";
import {
  AUTH_PATH,
  AUTH_PATH_LOGOUT_OUT
} from "../common/constants";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@govbr/dsgov/dist/css/dsgov.css";
import "../packages/cdn-package/fonts/rawline/css/rawline.css";

import "@govbr/react-components/src/styles/custom-styles.scss";
import "./css/styles.css";
import "./css/uikit-dsgov-pmf.3.0.0.css";

import Grid from "@material-ui/core/Grid";

import PaginaInfoApp from "./PaginaInfoApp";
import PaginaNaoAutorizado from "./PaginaNaoAutorizado";
import PaginaNaoEncontrada from "./PaginaNaoEncontrada";
import ComunicacaoExterna from "./comunicacao/ComunicacaoExterna";
import ConsultasAPI from "./consultasAPI";
import Dicionario from "./dicionario";
import ConsultaPericias from "./painelGerencial/ConsultaPericias";
import LoaderPericia from "./pericia/LoaderPericia";
import Header from "./principal/Header";
import Principal from "./principal/Principal";

import BrButton from '@govbr/react-components/lib/BrButton';
import BrFooter from '@govbr/react-components/lib/BrFooter';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrModal from '@govbr/react-components/lib/BrModal';

import DialogSelecaoItemDicionario from './dicionario/DialogSelecaoItemDicionario';
import DialogRetornoSima from './pericia/modulos/SIMA/RetornoSIMA';

import { exibirDialogRetornoSima } from './pericia/modulos/SIMA/action';

import { possuiPermissoes } from '../common/AuthManager';
import history from '../common/history';
import * as permissoes from '../common/permissoes';
import { tiposPericia } from "./pericia/constants";

class App extends React.Component {
  static doLogin() {
    return window.open(AUTH_PATH, "_self");
  }

  static async doLogout() {
    await unStoreToken();
    return window.open(AUTH_PATH_LOGOUT_OUT, "_self");
  }

  constructor(props) {
    super(props);

    this.state = {
      activeMenuKey: 1,
      isPossuiPermissaoVisualizarPericia: possuiPermissoes([permissoes.CONSPER, permissoes.REAPER, permissoes.ACEDIRPER]),
      isPossuiPermissaoVisualizarComunicacoes: possuiPermissoes(permissoes.COEXPER),
      isPossuiPermissaoConsultaGerencialPericia: possuiPermissoes(permissoes.CONGERPER)
    };

    this.isMenuItemActive = this.isMenuItemActive.bind(this);
    this.isDropdownMenuItemActive = this.isDropdownMenuItemActive.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }
  isMenuItemActive(key) {
    const { activeMenuKey } = this.state;
    return key && activeMenuKey && activeMenuKey === key;
  }

  isDropdownMenuItemActive(key) {
    const { activeMenuKey } = this.state;
    return (
      key && activeMenuKey && activeMenuKey >= key && activeMenuKey < key + 1
    );
  }

  handleMenuItemClick(key) {
    this.setState({ activeMenuKey: key });
  }

  componentDidMount() {
    window.addEventListener("offline", function (event) {
      let offLineComponent = document.getElementById("status_offline");
      offLineComponent.classList.remove("status_offline_hide");
    });

    window.addEventListener("online", function (event) {
      let offLineComponent = document.getElementById("status_offline");
      offLineComponent.classList.add("status_offline_hide");
    });
  }

  render() {
    const { userData, mensagem, mensagemConsulta, tipoMensagem, isPendenteSima, exibirDialogRetornoSima, situacaoPericia, tipoPericia, 
      cenarioTransformacao,idPericiaAnterior,permiteAlteracaoCritica, isSomenteLeitura } = this.props;

    const appInfo = {
      cabecalho: "Perícia Médica Federal",
      titulo: "PMF Perícias",
      subtitulo: tipoPericia && tiposPericia[tipoPericia].descricao
    };

    const isUsuarioAutorizado = userData.gerid;
    const isPericiaConcluida = situacaoPericia && situacaoPericia.id === 4;
    const isPericiaCancelada = situacaoPericia && situacaoPericia.id ===2;
    const isPericiaRevisada = situacaoPericia && situacaoPericia.id === 5;


    return (
      <div>
        <Header info={appInfo} user={userData} handleLogout={App.doLogout} />
        <main id="main">
          <div className="container-lg" style={{ paddingBottom: '20px', minHeight: '400px' }}>
            <div className="row" id="container-grid">
              {mensagem &&
                (<BrMessage {...tipoMensagem}>
                  {tipoMensagem.error &&
                    (<strong>Erro</strong>)
                  }
                  <p>{mensagem}</p>
                </BrMessage>)
              }
              {mensagemConsulta &&
                (<BrMessage {...tipoMensagem}>
                  {tipoMensagem.success &&
                    (<strong>Sucesso</strong>)
                  }
                  <p dangerouslySetInnerHTML={{ __html: mensagemConsulta }}></p>
                </BrMessage>)
              }
              {isPendenteSima &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        Avaliação médico pericial interrompida por Solicitação de Exigência. Para registrar o cumprimento da exigência
                      </span>
                    </Grid>
                    <Grid item >
                      <BrButton id="btnLinkRetornoSima" onClick={() => exibirDialogRetornoSima(true)} secondary>
                        clique aqui
                      </BrButton>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }
              {isPericiaConcluida &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        O serviço encontra-se concluído
                      </span>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }
              {isPericiaRevisada &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        O serviço encontra-se revisado
                      </span>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }
              {isPericiaCancelada &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        O serviço encontra-se cancelado
                      </span>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }

              {idPericiaAnterior &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        A perícia foi gerada de forma automática por duplicação{cenarioTransformacao ? " no fluxo de transformação" : ""}.
                      </span>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }
              {permiteAlteracaoCritica === false && !isSomenteLeitura &&
                (<BrMessage warning>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="mr-2">
                        Para atualizar os campos desabilitados, é necessário proceder à revisão da avaliação subsequente, pois esses campos foram reavaliados na prorrogação do benefício.
                      </span>
                    </Grid>
                  </Grid>
                </BrMessage>)
              }

              <Router history={history}>
                <Switch>
                  <Route exact path="/naoAutorizado" >
                    <PaginaNaoAutorizado />
                  </Route>
                  <Route exact path="/naoEncontrado" >
                    <PaginaNaoEncontrada />
                  </Route>
                  <Route exact path="/infoapp" >
                    <PaginaInfoApp />
                  </Route>
                  <Route exact path="/comunicacao" >
                    {isUsuarioAutorizado && this.state.isPossuiPermissaoVisualizarComunicacoes
                      ? <ComunicacaoExterna />
                      : <Redirect to="/naoAutorizado" />}
                  </Route>
                  <Route path="/pericia" exact render={(props) => (
                    <>
                      {isUsuarioAutorizado && props.location.search && this.state.isPossuiPermissaoVisualizarPericia ?
                        <LoaderPericia search={props.location.search} /> :
                        <Redirect to="/naoAutorizado" />}
                    </>
                  )} />
                  <Route path="/consulta/pericia" exact>
                    {isUsuarioAutorizado && this.state.isPossuiPermissaoConsultaGerencialPericia
                      ? <ConsultaPericias />
                      : <Redirect to="/naoAutorizado" />}
                  </Route>
                  <Route path="/consulta/pericia/detalhe" exact render={(props) => (
                    <>
                      {isUsuarioAutorizado && props.location.search && this.state.isPossuiPermissaoVisualizarPericia ?
                        <LoaderPericia search={props.location.search} somenteLeitura={true} /> :
                        <Redirect to="/naoAutorizado" />}
                    </>
                  )} />
                  <Route path="/consulta/pericia/detalhe/*" render={(props) =>
                    <>
                      {
                        isUsuarioAutorizado && this.state.isPossuiPermissaoVisualizarPericia ?
                          <Principal user={this.props.userData} somenteLeitura={true} /> :
                          <Redirect to="/naoAutorizado" />
                      }
                    </>} />
                  <Route path="/pericia/*" render={(props) =>
                    <>
                      {
                        isUsuarioAutorizado && this.state.isPossuiPermissaoVisualizarPericia ?
                          <Principal user={this.props.userData} /> :
                          <Redirect to="/naoAutorizado" />
                      }
                    </>} />
                  <Route exact path="/dicionario" >
                    <Dicionario />
                  </Route>
                  <Route exact path="/consultasAPI">
                    <ConsultasAPI />
                  </Route>
                  <Route>
                    <Redirect to="/naoEncontrado" />
                  </Route>
                </Switch>


              </Router>
            </div>
          </div>
        </main>

        <BrFooter licence={`Desenvolvido por Dataprev v${process.env.REACT_APP_VERSION} - BUILD 0`} />

        <DialogRetornoSima />
        <DialogSelecaoItemDicionario />

        <BrModal xsmall visible={this.props.loader} closable={false}>
          Carregando...
        </BrModal>
      </div>
    );
  }
}

App.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    document: PropTypes.shape({
      label: PropTypes.string,
      number: PropTypes.string
    }),
    information: PropTypes.string
  })
};

App.defaultProps = {
  userData: {}
};

const mapStateToProps = (state) => {
  
  const { userData, loader, mensagem, mensagemConsulta, tipoMensagem, isPendenteSima, template, isSomenteLeitura } = state.principal;
  const { situacao, cenarioTransformacao, idPericiaAnterior, permiteAlteracaoCritica} = state.pericia;

  return ({
    userData: userData,
    loader: loader,
    mensagem: mensagem,
    tipoMensagem: tipoMensagem,
    mensagemConsulta: mensagemConsulta,
    isPendenteSima: isPendenteSima,
    situacaoPericia: situacao,
    cenarioTransformacao: cenarioTransformacao,
    idPericiaAnterior: idPericiaAnterior,
    tipoPericia: template.tipoPericia,
    permiteAlteracaoCritica: permiteAlteracaoCritica,
    isSomenteLeitura: isSomenteLeitura
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  exibirDialogRetornoSima
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
