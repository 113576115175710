import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";
import { SIGLA_CAMPO_CID_ALTERADO } from '../../constants';

export const verificarSeCamposPreenchidos = pericia => {

  const moduloCid = pericia?.moduloCid;

  const deveValidarCIDs = moduloCid
    && (moduloCid.cidPrincipal
      || moduloCid.naoExisteCidSecundario !== null
      || (moduloCid.cidsSecundarios && moduloCid.cidsSecundarios.length > 0));

  return deveValidarCIDs;

}

export const validar = (pericia, getState) => {

  const cidPrincipal = pericia?.moduloCid?.cidPrincipal;
  const cidPrincipalDigitado = getState().modulos.moduloCids.inputCidPrincipal;
  const cidsSecundarios = pericia?.moduloCid?.cidsSecundarios;
  const naoExisteCidSecundario = pericia?.moduloCid?.naoExisteCidSecundario;

  const valorCampoCidAlterado = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO);
  const cidAlterado = valorCampoCidAlterado && valorCampoCidAlterado.valorTextoCampoGenerico === '1';
  const { isCidPricipalMesmoGrupo } = getState().modulos.moduloCids;
  const deveCompararCidPrincipalComCidAnterior = cidAlterado && pericia.periciaAnterior && pericia.periciaAnterior.codigoCID;

  const listaCidsSecundarios = cidsSecundarios && cidsSecundarios.length > 0
    ? cidsSecundarios.map(c => c.cidSecundario)
    : null;

  let erroCidPrincipal = cidPrincipal == null ? MSG_CAMPO_OBRIGATORIO : null;
  if (!erroCidPrincipal) {

    if (cidPrincipalDigitado && cidPrincipal !== cidPrincipalDigitado) {
      erroCidPrincipal = "Um valor de CID Principal válido deve ser selecionado."
    } else if (deveCompararCidPrincipalComCidAnterior && cidPrincipal.trim() === pericia.periciaAnterior.codigoCID.trim()) {
      erroCidPrincipal = "Ao indicar Diagnóstico com alteração de CID, deve-se informar CID principal diferente do CID principal da perícia anterior.";
    } else if (deveCompararCidPrincipalComCidAnterior && isCidPricipalMesmoGrupo) {
      erroCidPrincipal = "Ao indicar Diagnóstico com alteração de CID, deve-se informar CID principal de grupo diferente do CID principal da perícia anterior.";
    } else {
      erroCidPrincipal = cidPrincipal && listaCidsSecundarios && listaCidsSecundarios.includes(cidPrincipal)
        ? "O CID secundário não pode ser igual ao principal"
        : null;
    }
  }

  const erroRespostaObrigatoria = (!naoExisteCidSecundario && (!cidsSecundarios || cidsSecundarios.length === 0))
    ? "É obrigatório marcar opção se não existem CIDs secundários"
    : null;

  const erroOpcaoIncompativel = (naoExisteCidSecundario === 1 && cidsSecundarios && cidsSecundarios.length > 0)
    ? `Opção incompatível com ${cidsSecundarios.length} CID(s) secundário(s). Desmarque a opção ou exclua o(s) CID(s) secundário(s).`
    : null;

  return {
    isValida: !erroCidPrincipal && !erroRespostaObrigatoria && !erroOpcaoIncompativel,
    erroCidPrincipal: erroCidPrincipal,
    erroNaoExisteCidSecundario: erroRespostaObrigatoria ? erroRespostaObrigatoria : erroOpcaoIncompativel
  };
}