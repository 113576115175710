import { isCidsDoMesmoGrupo } from '../../../principal/action';

export const carregarBatimentoCidBeneficioAgravamento = (cidPrincipal, cidBeneficio) =>
  isCidsDoMesmoGrupo(cidPrincipal, cidBeneficio, atualizarBatimentoCidAgravamento, atualizarBatimentoCidAgravamentoComErro, true);

export const atualizarBatimentoCidAgravamento = (cid, batimento) => ({
  type: 'ATUALIZAR_BATIMENTO_CID_AGRAVAMENTO',
  payload: {
    cid: cid,
    batimento: batimento
  }
});

export const limparBatimentoCidAgravamento = () => ({
  type: 'LIMPAR_BATIMENTO_CID_AGRAVAMENTO'
});

const atualizarBatimentoCidAgravamentoComErro = cid => ({
  type: 'ERRO_CONSULTA_BATIMENTO_CID_AGRAVAMENTO',
  payload: cid
});

export const carregarBatimentoCidBeneficioRestabelecimento = (cidPrincipal, cidBeneficio) =>
  isCidsDoMesmoGrupo(cidPrincipal, cidBeneficio, atualizarBatimentoCidRestabelecimento, atualizarBatimentoCidRestabelecimentoComErro);

export const atualizarBatimentoCidRestabelecimento = (cid, batimento) => ({
  type: 'ATUALIZAR_BATIMENTO_CID_RESTABELECIMENTO',
  payload: {
    cid: cid,
    batimento: batimento
  }
});

const atualizarBatimentoCidRestabelecimentoComErro = cid => ({
  type: 'ERRO_CONSULTA_BATIMENTO_CID_RESTABELECIMENTO',
  payload: cid
});

export const limparBatimentoCidRestabelecimento = () => ({
  type: 'LIMPAR_BATIMENTO_CID_RESTABELECIMENTO'
});
export const indicarBatimentosFinalizadosAgravamento = () => ({
  type: 'FIM_BATIMENTO_CID_AGRAVAMENTO'
});

export const indicarBatimentosFinalizadosRestabelecimento = () => ({
  type: 'FIM_BATIMENTO_CID_RESTABELECIMENTO'
});

export const indicarNaoPossuiDIIRestabelecimentoOuAgravamento = possuiDID => ({
  type: 'INDICAR_POSSUI_DII_RESTABELECIMENTO_AGRAVAMENTO',
  payload: possuiDID
});