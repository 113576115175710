import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import BrTooltip from '@govbr/react-components/lib/BrTooltip';
import { Box, Grid } from "@material-ui/core";
import ButtonCheck from './ButtonCheck';
import ButtonXMark from './ButtonXMark';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import HeaderSecao from '../../../../components/HeaderSecao';

import { converterDataFormatadaParaISOBr, convertStringToDate, formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { SIGLA_CAMPO_CID_ALTERADO, SIGLA_CAMPO_DII, tipoSimNao, tiposNexoTecnico } from '../../constants';

import {
    alterarExisteNexoEpidemiologico, alterarExisteNexoIndividual, alterarExisteNexoProfissional, alterarFatoresRiscoProfissionalSelecionados,
    alterarFundamentacaoNaoFixacaoNtep, alterarFundamentacaoNexoIndividual, alterarFundamentacaoNexoProfissional,
    alterarJustifNegacaoNexoProfissional, alterarMotivoNaoFixacaoNtep, alterarTipoDoencaNexoProfissional,
    alterarTipoNexoIndividual, relacionarVinculoAoNexo
} from '../../action';
import { consultarBatimentoCidCnae, consultarFatoresDeRiscoCid } from './action';

import {
    INFO_ACIDENTE_TIPICO, INFO_ACIDENTE_TRAJETO, INFO_DOENCA_EQUIPARADA, INFO_DOENCA_PROFISSIONAL, INFO_DOENCA_TRABALHO,
    INFO_NEXO_EPIDEMIOLOGICO, INFO_NEXO_PROFISSIONAL
} from './tooltipContents';
import {
    isRespostaCamposGenericosNaoPermiteNexo,
    JUSTIFICATIVA_CARACTERIZACAO_AUTOMATICA_NTEP,
    obterLabelVinculoNexo,
    obterListaCnaesDeVinculos, obterVinculosFiltradosPorTipoFiliacao,
    obterVinculosParaRelacaoNexoEpidemiologico
} from './utils';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

const NexoTecnico = props => {

  const QTD_MAXIMA_CARACTERES = 4000;
  const OPCAO_VAZIA = { label: '', value: null };


  const { pericia, batimentosNexo, dominioPericia, validacao, isSomenteLeitura,
    relacionarVinculoAoNexo, alterarExisteNexoEpidemiologico, consultarFatoresDeRiscoCid, consultarBatimentoCidCnae } = props;
  const { tiposDoencaNexoProfissional, tiposNexoIndividual, tiposSimNao, motivosNaoFixacaoNtep } = dominioPericia;
  const { fatoresRiscoCid, batimentoCidCnae, erroConsultaBatimentoCidCnae, erroConsultaFatoresRiscoCid } = batimentosNexo;
  const { existeNexoProfissional, fatoresRiscoProfissional, tipoDoencaNexoProfissional,
    fundamentacaoNexoProfissional, justifNegacaoNexoProfissional,
    existeNexoIndividual, tipoNexoIndividual, fundamentacaoNexoIndividual } = pericia.moduloNexoTecnico;

  const vinculosSelecionadosNexoProfissional = pericia?.vinculosPericia
    ? pericia.vinculosPericia.filter(v => v.tipoNexoTecnico === tiposNexoTecnico.PROFISSIONAL.id).map(v => v.id)
    : [];
  const vinculosSelecionadosNexoIndividual = pericia?.vinculosPericia
    ? pericia.vinculosPericia.filter(v => v.tipoNexoTecnico === tiposNexoTecnico.INDIVIDUAL.id).map(v => v.id)
    : [];

  /**
   * Variaveis auxiliares de interface
   */
  const opcoesMotivoNaoFixacaoNtep = motivosNaoFixacaoNtep ? motivosNaoFixacaoNtep.map(t => ({ label: t.descricao, value: t.id })) : [];
  opcoesMotivoNaoFixacaoNtep.unshift(OPCAO_VAZIA);

  const [cid, setCid] = useState('');
  const [dii, setDii] = useState(undefined);
  const [cidMantido, setCidMantido] = useState(undefined);
  const [exibeNexoCid, setExibeNexoCid] = useState(undefined);

  const [isDecisaoCapacidadeLaborativaNaoPermiteNexo, setIsDecisaoCapacidadeLaborativaNaoPermiteNexo] = useState(undefined);

  const [existemVinculos, setExistemVinculos] = useState(false);
  const [exibeNexo, setExibeNexo] = useState(false);
  const [existeFatoresRisco, setExisteFatoresRisco] = useState(true);
  const [existeBatimentoCidCnae, setExisteBatimentoCidCnae] = useState(undefined);
  const [exibeNexoIndividual, setExibeNexoIndividual] = useState(undefined);
  const [exibeNexoProfissional, setExibeNexoProfissional] = useState(undefined);
  const [exibeNexoEpidemiologico, setExibeNexoEpidemiologico] = useState(undefined);
  const [existeAlgumNexoAplicavel, setExisteAlgumNexoAplicavel] = useState(undefined);
  const [deveExibirMensagemAlertaNexoEpidemiologico, setDeveExibirMensagemAlertaNexoEpidemiologico] = useState(undefined);
  const [nexoIndividualNaoSelecionado, setNexoIndividualNaoSelecionado] = useState(undefined);

  const [listaVinculosNexoProfissional, setListaVinculosNexoProfissional] = useState(undefined);
  const [listaVinculosNexoIndividual, setListaVinculosNexoIndividual] = useState(undefined);
  const [listaVinculosNexoEpidemiologico, setListaVinculosNexoEpidemiologico] = useState(undefined);

  const caractRestantesFundNexoProfissional = calcularCaracteresRestantes(pericia.moduloNexoTecnico.fundamentacaoNexoProfissional, QTD_MAXIMA_CARACTERES);
  const caractRestantesJustifNegacaoNexoProfissional = calcularCaracteresRestantes(pericia.moduloNexoTecnico.justifNegacaoNexoProfissional, QTD_MAXIMA_CARACTERES);
  const caractRestantesFundNexoIndividual = calcularCaracteresRestantes(pericia.moduloNexoTecnico.fundamentacaoNexoIndividual, QTD_MAXIMA_CARACTERES);
  const [listaCaracteresRestantesFundNtepDescarac, setListaCaracteresRestantesFundNtepDescarac] = useState([]);
  /**
   * -------------
   */

  /**
   * Variaveis de tooltips
   */
  const doencaProfissionalRef = useRef();
  const doencaDoTrabalhoRef = useRef();
  const acidenteTipicoRef = useRef();
  const acidenteTrajetoRef = useRef();
  const doencaEquiparadaRef = useRef();

  const [openTooltipDoencaProfissional, setOpenTooltipDoencaProfissional] = useState(false);
  const [openTooltipDoencaDoTrabalho, setOpenTooltipDoencaDoTrabalho] = useState(false);
  const [openTooltipAcidenteTipico, setOpenTooltipAcidenteTipico] = useState(false);
  const [openTooltipAcidenteTrajeto, setOpenTooltipAcidenteTrajeto] = useState(false);
  const [openTooltipDoencaEquiparada, setOpenTooltipDoencaEquiparada] = useState(false);
  const [listaRefsInfoFatoresRisco, setListaRefsInfoFatoresRisco] = useState(undefined);

  const handleTooltipInfoFatorRisco = (idFatorRiscoCID, isOpen) => {
    let novosValores = listaRefsInfoFatoresRisco.slice();

    novosValores[idFatorRiscoCID] = { ...novosValores[idFatorRiscoCID], isOpen: isOpen };

    setListaRefsInfoFatoresRisco(novosValores);
  };

  const tratarSelecaoFatorRiscoProfissional = (item, checked) => {
    const idPericia = pericia.id;
    let fatoresRiscoAtualizados = fatoresRiscoProfissional ? fatoresRiscoProfissional : [];
    const value = parseInt(item);

    if (checked) { //inserir
      fatoresRiscoAtualizados.push({ idFatorRiscoOcupacional: value, idPericia });
    } else { //remover
      fatoresRiscoAtualizados = fatoresRiscoAtualizados.filter(e => { return e.idFatorRiscoOcupacional !== value });
    }
    props.alterarFatoresRiscoProfissionalSelecionados(fatoresRiscoAtualizados);
  }

  const tratarAlteracaoExisteNexoProfissional = value => {
    const valor = parseInt(value);
    props.alterarExisteNexoProfissional(valor);

    if (valor === 0 //se NAO existe nexo profissional
      && nexoIndividualNaoSelecionado //e se NAO existe nexo individual
      && listaVinculosNexoEpidemiologico?.length > 0) {// e existe batimento nexo epidemiologico
      // fazer marcacao automatica NTEP ao exibir para avaliacao
      marcarAutomaticamenteCaracterizacaoNtep(listaVinculosNexoEpidemiologico);
    }
  }

  const tratarAlteracaoExisteNexoIndividual = value => {
    const valor = parseInt(value);
    props.alterarExisteNexoIndividual(valor);

    if (valor === 0 //se NAO existe nexo individual
      && listaVinculosNexoEpidemiologico?.length > 0) {// e existe batimento nexo epidemiologico
      // fazer marcacao automatica NTEP ao exibir para avaliacao
      marcarAutomaticamenteCaracterizacaoNtep(listaVinculosNexoEpidemiologico);
      props.alterarExisteNexoEpidemiologico(tipoSimNao.SIM.id);
    }
  }

  const indicarNexoEpidemiologicoParaVinculo = (idVinculo, existeNexo) => {
    relacionarVinculoAoNexo(idVinculo, existeNexo, tiposNexoTecnico.EPIDEMIOLOGICO.id);

    const listaVinculosNtepDescaracterizados = listaVinculosNexoEpidemiologico
      .filter(v => v.possuiNexoTecnico === 0 && v.id !== idVinculo);
    const valorNtepVinculo = existeNexo ? 1 : 0;
    const valorFinalNtep = listaVinculosNtepDescaracterizados?.length > 0
      ? 0 //pelo menos um vinculo descaracterizado, nao existe NTEP
      : valorNtepVinculo; //nenhum nexo descaracterizado, sera descaracterizado se vinculo atual for

    props.alterarExisteNexoEpidemiologico(valorFinalNtep);
  }

  const marcarAutomaticamenteCaracterizacaoNtep = (vinculosEpidemiologicos) => {
    vinculosEpidemiologicos.forEach(v => {
      relacionarVinculoAoNexo(v.id, true, tiposNexoTecnico.EPIDEMIOLOGICO.id);
    });
  }

  useEffect(() => {

    const cidAlterado = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO);
    const valorCidMantido = cidAlterado && cidAlterado.valorTextoCampoGenerico && cidAlterado.valorTextoCampoGenerico === '0' ? true : false;
    const exibeNexoCid = cidAlterado && cidAlterado.valorTextoCampoGenerico ? (cidAlterado.valorTextoCampoGenerico === '1' ? true : false) : true;
    setCidMantido(valorCidMantido);
    setExibeNexoCid(exibeNexoCid);

    const cidPrincipal = pericia.moduloCid?.cidPrincipal;
    const der = converterDataFormatadaParaISOBr(pericia.dataReferencia);

    const campoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
    const valorCampoDii = campoDii && campoDii.valorTextoCampoGenerico && convertStringToDate(campoDii.valorTextoCampoGenerico);
    const decisaoCapacidadeLaborativaNaoPermiteNexo = isRespostaCamposGenericosNaoPermiteNexo(pericia.valoresCamposGenericos);

    setCid(cidPrincipal);
    setDii(valorCampoDii);
    setIsDecisaoCapacidadeLaborativaNaoPermiteNexo(decisaoCapacidadeLaborativaNaoPermiteNexo);

    const vinculos = pericia.vinculosPericia;

    // regra de negocio: filtrando vinculos que podem ser enviados ao SIBE
    const vinculosElegiveisParaEnvioAoSibe = vinculos?.filter(v => v.elegivelParaEnvioAoSibe);

    const existemVinculos = vinculosElegiveisParaEnvioAoSibe?.length > 0;
    setExistemVinculos(existemVinculos);

    //regra de negocio: filtrando vinculos nexo por tipo de filiacao
    const vinculosFiltrados = obterVinculosFiltradosPorTipoFiliacao(vinculosElegiveisParaEnvioAoSibe, valorCampoDii);
    const existeFatoresRisco = fatoresRiscoCid && fatoresRiscoCid.length > 0;
    const exibeNexo = cidPrincipal && existemVinculos && valorCampoDii && !decisaoCapacidadeLaborativaNaoPermiteNexo && exibeNexoCid;

    //regra de negocio: vinculos para nexo epidemiologico precisam estar marcados para incapacidade laborativa
    const vinculosComIncapacidade = vinculosElegiveisParaEnvioAoSibe?.filter(v => v.possuiIncapacidade === 1);

    setExibeNexo(exibeNexo);
    setExisteFatoresRisco(existeFatoresRisco);

    let vinculosEpidemiologicos = undefined;
    let vinculosProfissionais = undefined;

    //busca fatores de risco associados ao CID principal 
    if (exibeNexo && fatoresRiscoCid === undefined) {
      consultarFatoresDeRiscoCid(cidPrincipal);
    }

    //busca batimento CID principal x CNAE vinculos 
    if (exibeNexo && existeBatimentoCidCnae === undefined) {
      consultarBatimentoCidCnae(cidPrincipal, obterListaCnaesDeVinculos(pericia?.vinculosPericia));
      setExisteBatimentoCidCnae(false); //ocultando campos que dependem de batimento ate q sejam efetivamente calculados
    }

    if (batimentoCidCnae && batimentoCidCnae.length > 0) {
      //TODO: verificar data validade batimento, considerar cenario de consulta (batimento nao é valido na data atual mas era na data da realizacao)
      setExisteBatimentoCidCnae(true);

      //regra de negocio: preenche lista de vinculos para associacao com nexo epidemiologico
      vinculosEpidemiologicos = obterVinculosParaRelacaoNexoEpidemiologico(vinculosComIncapacidade, batimentoCidCnae, der, valorCampoDii);

      setListaVinculosNexoEpidemiologico(vinculosEpidemiologicos);
    }

    if (existeFatoresRisco) {

      if (!listaRefsInfoFatoresRisco) {
        //preenche lista de refs para exibir tooltip com detalhamento de cada fator de risco
        setListaRefsInfoFatoresRisco(fatoresRiscoCid && fatoresRiscoCid.reduce((array, f) => {
          array[f.idFatorRiscoCID] = ({
            ref: React.createRef(),
            isOpen: false
          });
          return array;
        }, []));
      }

      //regra de negocio: preenche lista de vinculos para associacao com nexo profissional
      vinculosProfissionais = vinculosFiltrados;

      setListaVinculosNexoProfissional(vinculosProfissionais);
    }

    //regra de negocio: preenche lista de vinculos para associacao com nexo individual
    const vinculosIndividuais = vinculosFiltrados;

    setListaVinculosNexoIndividual(vinculosIndividuais);

    const nexoProfissionalNaoSelecionado = !existeFatoresRisco || !vinculosProfissionais || vinculosProfissionais.length === 0
      || pericia.moduloNexoTecnico.existeNexoProfissional === 0;

    setExibeNexoProfissional(exibeNexo //pre requisitos nexo atendidos
      && existeFatoresRisco //com batimento fatores de risco
      && vinculosProfissionais?.length > 0); //existem vinculos para regras nexo profissional

    setExibeNexoIndividual(exibeNexo //pre requisitos nexo atendidos
      && nexoProfissionalNaoSelecionado //sem fatores risco ou com fatores de risco e NAO para nexo profissional
      && vinculosIndividuais?.length > 0); //existem vinculos para regras nexo individual

    const nexoIndividualNaoSelecionado = !vinculosIndividuais || vinculosIndividuais.length === 0
      || pericia.moduloNexoTecnico.existeNexoIndividual === 0; //NAO para nexo individual
    setNexoIndividualNaoSelecionado(nexoIndividualNaoSelecionado);

    setExisteAlgumNexoAplicavel(vinculosProfissionais?.length > 0 || vinculosIndividuais?.length > 0 || vinculosEpidemiologicos?.length > 0);

    setExibeNexoEpidemiologico(nexoProfissionalNaoSelecionado
      && nexoIndividualNaoSelecionado
      && existeBatimentoCidCnae
      && vinculosEpidemiologicos?.length > 0); //existem vinculos para regras nexo epidemiologico

    setDeveExibirMensagemAlertaNexoEpidemiologico(nexoProfissionalNaoSelecionado
      && nexoIndividualNaoSelecionado
      && existeBatimentoCidCnae
      && (!vinculosEpidemiologicos || vinculosEpidemiologicos.length === 0)
      && (!vinculosComIncapacidade || vinculosComIncapacidade.length === 0)
    );

    if (exibeNexoProfissional && pericia.moduloNexoTecnico.existeNexoProfissional === 1 && vinculosProfissionais?.length === 1 &&
      (!vinculosProfissionais[0].possuiNexoTecnico || vinculosProfissionais[0].tipoNexoTecnico !== tiposNexoTecnico.PROFISSIONAL.id)) {
      relacionarVinculoAoNexo(vinculosProfissionais[0].id, true, tiposNexoTecnico.PROFISSIONAL.id)
    }

    if (exibeNexoIndividual && pericia.moduloNexoTecnico.existeNexoIndividual === 1 && vinculosIndividuais?.length === 1 &&
      (!vinculosIndividuais[0].possuiNexoTecnico || vinculosIndividuais[0].tipoNexoTecnico !== tiposNexoTecnico.INDIVIDUAL.id)) {
      relacionarVinculoAoNexo(vinculosIndividuais[0].id, true, tiposNexoTecnico.INDIVIDUAL.id)
    }

    if (exibeNexoEpidemiologico && vinculosEpidemiologicos?.length > 0) {
      let deveAtualizarExisteNtep = false;
      //preenche caracterizacao automatica nexo epidemiologico
      vinculosEpidemiologicos.forEach(v => {
        if (v.possuiNexoTecnico === undefined) {
          deveAtualizarExisteNtep = true;
          relacionarVinculoAoNexo(v.id, true, tiposNexoTecnico.EPIDEMIOLOGICO.id);
        }
      });

      const listaVinculosNtepDescaracterizados = vinculosEpidemiologicos.filter(v => v.possuiNexoTecnico === 0);

      //verificacao necessaria para evitar loop infinito de atualizacao do state
      if (deveAtualizarExisteNtep || pericia.moduloNexoTecnico.existeNtep === undefined) {
        alterarExisteNexoEpidemiologico(listaVinculosNtepDescaracterizados?.length > 0
          ? 0 //pelo menos um vinculo descaracterizado, nao existe NTEP
          : 1); //nenhum nexo descaracterizado, existe NTEP
      }

      //atualiza lista contadores de caracteres restantes fundamentacao para descaracterizacao de vinculo para NTEP
      setListaCaracteresRestantesFundNtepDescarac(listaVinculosNtepDescaracterizados.reduce((array, v) => {
        array[v.id] = calcularCaracteresRestantes(v.descaracterizacaoNtep?.fundamentacaoNaoFixacaoNtep, QTD_MAXIMA_CARACTERES);
        return array;
      }, []));

    }
  }, [pericia, fatoresRiscoCid, batimentoCidCnae, listaRefsInfoFatoresRisco, existeBatimentoCidCnae,
    consultarFatoresDeRiscoCid, consultarBatimentoCidCnae, relacionarVinculoAoNexo, alterarExisteNexoEpidemiologico,
    exibeNexoIndividual, exibeNexoEpidemiologico, exibeNexoProfissional]);

  const isCampoFundamentacaoNexoProfissionalDesabilitado = isSomenteLeitura;
  const isCampoJustNegacaoNexoProfissionalDesabilitado = isSomenteLeitura;
  const isCampoFundamentacaoNexoIndividualDesabilitado = isSomenteLeitura;
  const isCampoFundamentacaoNaoFixacaoNtepDesabilitado = isSomenteLeitura;

  return (
    <>
      <div id="moduloNexoTecnico">
        {
          cidMantido &&
          (
            <BrMessage warning>
              Não cabe a verificação do Nexo Técnico, pois o CID principal foi mantido.
            </BrMessage>
          )
        }
        {exibeNexoCid &&
          isDecisaoCapacidadeLaborativaNaoPermiteNexo &&
          (
            <BrMessage warning>
              Não cabe a verificação do Nexo Técnico, pois existe decisão incompatível na capacidade laborativa.
            </BrMessage>
          )
        }
        {exibeNexoCid &&
          !cid && !isDecisaoCapacidadeLaborativaNaoPermiteNexo &&
          (
            <BrMessage warning>
              É necessário preencher o CID Principal em 'Diagnóstico' para avaliar o Nexo Técnico.
            </BrMessage>
          )
        }
        {exibeNexoCid &&
          !dii && !isDecisaoCapacidadeLaborativaNaoPermiteNexo &&
          (
            <BrMessage warning>
              É necessário preencher a Data de Início da Incapacidade (DII)  em 'Capacidade Laborativa' para avaliar o Nexo Técnico.
            </BrMessage>
          )
        }
        {exibeNexoCid &&
          !existemVinculos &&
          (
            <BrMessage warning>
              Segurado sem vínculo empregatício, não cabe a verificação de Nexo Técnico.
            </BrMessage>
          )
        }
        {
          exibeNexo && existeAlgumNexoAplicavel &&
          (
            <BrMessage warning>
              O enquadramento do Nexo Técnico resulta em isenção de carência sem a necessidade de justificativa.
            </BrMessage>
          )
        }
        {
          exibeNexo && !existeFatoresRisco && !erroConsultaFatoresRiscoCid &&
          (
            <BrMessage warning>
              {`Nexo Técnico Profissional: Não houve correlação entre CID '${cid}' informado e fator(es) de risco.`}
            </BrMessage>
          )
        }
        {
          exibeNexo && erroConsultaFatoresRiscoCid &&
          (
            <BrMessage error>
              {`Não foi possível obter a correlação entre o CID '${cid}' informado e fator(es) de risco.`}
            </BrMessage>
          )
        }
        {
          exibeNexo && existeFatoresRisco && (!listaVinculosNexoProfissional || listaVinculosNexoProfissional.length === 0) &&
          (
            <BrMessage warning>
              Nexo Técnico Profissional: Não existem vínculos compatíveis.
            </BrMessage>
          )
        }
        {
          exibeNexo && (!listaVinculosNexoIndividual || listaVinculosNexoIndividual.length === 0) &&
          (
            <BrMessage warning>
              Nexo Técnico Individual: Não existem vínculos compatíveis.
            </BrMessage>
          )
        }
        {
          exibeNexo && !existeBatimentoCidCnae && cid && !erroConsultaBatimentoCidCnae &&
          (
            <BrMessage warning>
              {`Nexo Técnico Epidemiológico: O sistema não identificou a correlação entre CID '${cid}' informado e` +
                ` CNAE conforme a lista C do Anexo II do Decreto 3.048/99.`}
            </BrMessage>
          )
        }
        {
          exibeNexo && erroConsultaBatimentoCidCnae &&
          (
            <BrMessage error>
              {`Não foi possível obter a correlação entre o CID '${cid}' informado e o CNAE dos vínculos do avaliado.`}
            </BrMessage>
          )
        }
        {
          exibeNexo && existeBatimentoCidCnae && (!listaVinculosNexoEpidemiologico || listaVinculosNexoEpidemiologico.length === 0) &&
          (
            <BrMessage warning>
              Nexo Técnico Epidemiológico: Não existem vínculos compatíveis.
            </BrMessage>
          )
        }

        {
          exibeNexo &&
          exibeNexoProfissional
          && (
            <>
              <HeaderSecao texto="Caracterização do Nexo Técnico Profissional" info={INFO_NEXO_PROFISSIONAL} />
              <p className="label">
                * Houve exposição a agente(s) relacionado(s) abaixo constante(s) na lista A e B do Anexo II do Dec. 3.048/99?
              </p>
              <Box display="flex">
                <Box mr={1}>
                  <BrRadio id="rdExisteNexoProfissional"
                    value={existeNexoProfissional}
                    items={tiposSimNao?.map(t => ({ label: t.descricao, value: t.id }))}
                    onChange={value => tratarAlteracaoExisteNexoProfissional(value)}
                    erro={validacao?.erroRespostaNexoProfissionalObrigatoria}
                    disabled={isSomenteLeitura}
                  />
                </Box>
                <Box mr={1}>
                  <ul>
                    {
                      fatoresRiscoCid.map(f => {
                        const infoFatorRisco = listaRefsInfoFatoresRisco && listaRefsInfoFatoresRisco[f.idFatorRiscoCID];
                        const descFatorRisco = f.descricaoFatorRisco && f.descricaoFatorRisco.split('#').reduce((str, value) => {
                          return (<>{str}{value} <br /> </>);
                        }, '');

                        return (
                          <li key={f.idFatorRiscoCID}>
                            <Box display="flex">
                              <Box flexGrow={1}>
                                {f.nomeFatorRisco}
                              </Box>
                              {
                                descFatorRisco && (
                                  <Box ml={1}>
                                    <BrTooltip
                                      id={`infoFatorRisco_${f.idFatorRiscoCID}`}
                                      anchorEl={infoFatorRisco.ref.current}
                                      open={infoFatorRisco.isOpen}
                                      onClose={() => handleTooltipInfoFatorRisco(f.idFatorRiscoCID, false)}
                                      top
                                      info
                                    >
                                      {descFatorRisco}
                                    </BrTooltip>
                                    <i className="fas fa-info-circle" aria-hidden="true"
                                      ref={infoFatorRisco.ref}
                                      onClick={() => handleTooltipInfoFatorRisco(f.idFatorRiscoCID, true)}
                                    />
                                  </Box>
                                )
                              }
                            </Box>
                          </li>
                        );
                      })
                    }
                  </ul>
                </Box>
              </Box>
            </>
          )
        }
        {
          exibeNexo &&
          existeNexoProfissional === 1 && //SIM para nexo profissional
          (
            <>
              <BrCheckbox id="fatoresDeRisco"
                label="* Selecione o(s) fator(es) de risco / agente(s) patogênico(s) identificado(s)"
                items={fatoresRiscoCid?.map(f => ({ label: f.nomeFatorRisco, value: f.idFatorRiscoOcupacional }))}
                values={fatoresRiscoProfissional ? fatoresRiscoProfissional.map(f => f.idFatorRiscoOcupacional) : []}
                onChange={tratarSelecaoFatorRiscoProfissional}
                erro={validacao?.erroFatoresRiscoObrigatorio}
                disabled={isSomenteLeitura}
              />

              <Grid container direction="row">
                <Grid item>
                  <BrRadio id="rdTipoDoenca"
                    label="* Tipo de doença"
                    value={tipoDoencaNexoProfissional}
                    items={tiposDoencaNexoProfissional?.map(t => ({ label: t.descricao, value: t.id }))}
                    onChange={value => props.alterarTipoDoencaNexoProfissional(parseInt(value))}
                    erro={validacao?.erroTipoDoencaNexoProfissionalObrigatoria}
                    disabled={isSomenteLeitura}
                  />
                </Grid>
                <Grid item>
                  <Box ml={-3} zIndex={1} position="absolute">
                    <Box pt={5}>
                      <BrTooltip
                        id="infoDoencaProfissional"
                        anchorEl={doencaProfissionalRef.current}
                        open={openTooltipDoencaProfissional}
                        onClose={() => setOpenTooltipDoencaProfissional(false)}
                        top
                        info
                      >
                        {INFO_DOENCA_PROFISSIONAL}
                      </BrTooltip>
                      <i className="fas fa-info-circle" aria-hidden="true"
                        ref={doencaProfissionalRef}
                        onClick={() => setOpenTooltipDoencaProfissional(true)}
                      />
                    </Box>
                    <Box pt={2}>
                      <BrTooltip
                        id="infoDoencaDoTrabalho"
                        anchorEl={doencaDoTrabalhoRef.current}
                        open={openTooltipDoencaDoTrabalho}
                        onClose={() => setOpenTooltipDoencaDoTrabalho(false)}
                        top
                        info
                      >
                        {INFO_DOENCA_TRABALHO}
                      </BrTooltip>
                      <i className="fas fa-info-circle" aria-hidden="true"
                        ref={doencaDoTrabalhoRef}
                        onClick={() => setOpenTooltipDoencaDoTrabalho(true)}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item>
                  <BrCheckbox id="vinculosNexoProfissional"
                    label="* Selecione o(s) vínculo(s) relacionado(s)"
                    items={listaVinculosNexoProfissional?.map(v => ({
                      label: obterLabelVinculoNexo(v),
                      value: v.id
                    }))}
                    values={vinculosSelecionadosNexoProfissional}
                    onChange={(item, checked) => { relacionarVinculoAoNexo(item, checked, tiposNexoTecnico.PROFISSIONAL.id) }}
                    erro={validacao?.erroVinculoNexoProfissionalObrigatorio}
                    disabled={isSomenteLeitura || listaVinculosNexoProfissional?.length === 1}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item xs>
                    <BrTextarea
                      id="fundamentacaoNexoProfissional"
                      label="* Fundamentação Técnica"
                      value={fundamentacaoNexoProfissional}
                      onChange={(value) => props.alterarFundamentacaoNexoProfissional(value)}
                      maxLength={QTD_MAXIMA_CARACTERES}
                      help={`Caracteres restantes: ${caractRestantesFundNexoProfissional}`}
                      erro={validacao?.erroFundamentacaoNexoProfissionalObrigatoria}
                      disabled={isCampoFundamentacaoNexoProfissionalDesabilitado}
                    />
                     </Grid>
                    {
                      !isCampoFundamentacaoNexoProfissionalDesabilitado &&
                      <Grid item >
                        <ButtonSelecionarItem
                          id="selecionarItemDicionarioFundamentacaoNexoProfissional"
                          onSelect={props.alterarFundamentacaoNexoProfissional}
                          maxLength={caractRestantesFundNexoProfissional}
                          valorAtual={fundamentacaoNexoProfissional}/>
                      </Grid>
                    }
                </Grid>
            </>
          )
        }
        {
          exibeNexo &&
          existeNexoProfissional === 0 && //NAO para nexo profissional
          (
            <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea
                    id="justificativaNegacaoNexoProfissional"
                    label="* Justifique a resposta NEGATIVA para exposição à agente(s) e/ou fator(es) de risco"
                    value={justifNegacaoNexoProfissional}
                    onChange={(value) => props.alterarJustifNegacaoNexoProfissional(value)}
                    maxLength={QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caractRestantesJustifNegacaoNexoProfissional}`}
                    erro={validacao?.erroJustifNegacaoNexoProfissionalObrigatoria}
                    disabled={isCampoJustNegacaoNexoProfissionalDesabilitado}
                  />
                </Grid>
                    {
                      !isCampoJustNegacaoNexoProfissionalDesabilitado &&
                      <Grid item >
                        <ButtonSelecionarItem
                          id="selecionarItemDicionarioJustNegacaoNexoProfissional"
                          onSelect={props.alterarJustifNegacaoNexoProfissional}
                          maxLength={caractRestantesJustifNegacaoNexoProfissional}
                          valorAtual={justifNegacaoNexoProfissional}/>
                      </Grid>
                    }
                </Grid>
          )
        }
        {
          exibeNexo &&
          exibeNexoIndividual &&
          (
            <>
              <HeaderSecao texto="* Caracterização do Nexo Técnico Individual" />
              <BrRadio id="rdExisteNexoIndividual"
                value={existeNexoIndividual}
                items={tiposSimNao?.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={value => tratarAlteracaoExisteNexoIndividual(value)}
                erro={validacao?.erroRespostaNexoIndividualObrigatoria}
                disabled={isSomenteLeitura}
              />
            </>
          )
        }
        {
          exibeNexo &&
          exibeNexoIndividual &&
          existeNexoIndividual === 1 && // SIM para nexo individual
          (
            <>
              <Grid container direction="row">
                <Grid item>
                  <BrRadio id="rdTipoNexoIndividual"
                    label="* Tipo de Nexo Técnico Individual"
                    value={tipoNexoIndividual}
                    items={tiposNexoIndividual?.map(t => ({ label: t.descricao, value: t.id }))}
                    onChange={value => props.alterarTipoNexoIndividual(value)}
                    erro={validacao?.erroTipoNexoIndividualObrigatorio}
                    disabled={isSomenteLeitura}
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Box pt={5} ml={-30} zIndex={1} position="absolute">
                      <BrTooltip
                        id="infoAcidenteTipico"
                        anchorEl={acidenteTipicoRef.current}
                        open={openTooltipAcidenteTipico}
                        onClose={() => setOpenTooltipAcidenteTipico(false)}
                        top
                        info
                      >
                        {INFO_ACIDENTE_TIPICO}
                      </BrTooltip>
                      <i className="fas fa-info-circle" aria-hidden="true"
                        ref={acidenteTipicoRef}
                        onClick={() => setOpenTooltipAcidenteTipico(true)}
                      />
                    </Box>
                    <Box pt={9} ml={-26} zIndex={2} position="absolute">
                      <BrTooltip
                        id="infoAcidenteTrajeto"
                        anchorEl={acidenteTrajetoRef.current}
                        open={openTooltipAcidenteTrajeto}
                        onClose={() => setOpenTooltipAcidenteTrajeto(false)}
                        top
                        info
                      >
                        {INFO_ACIDENTE_TRAJETO}
                      </BrTooltip>
                      <i className="fas fa-info-circle" aria-hidden="true"
                        ref={acidenteTrajetoRef}
                        onClick={() => setOpenTooltipAcidenteTrajeto(true)}
                      />
                    </Box>
                    <Box pt={14} ml={-3} zIndex={3} position="absolute">
                      <BrTooltip
                        id="infoDoencaEquiparada"
                        anchorEl={doencaEquiparadaRef.current}
                        open={openTooltipDoencaEquiparada}
                        onClose={() => setOpenTooltipDoencaEquiparada(false)}
                        top
                        info
                      >
                        {INFO_DOENCA_EQUIPARADA}
                      </BrTooltip>
                      <i className="fas fa-info-circle" aria-hidden="true"
                        ref={doencaEquiparadaRef}
                        onClick={() => setOpenTooltipDoencaEquiparada(true)}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <BrCheckbox id="vinculosNexoIndividual"
                label="* Selecione o(s) vínculo(s) relacionado(s)"
                items={listaVinculosNexoIndividual?.map(v => ({
                  label: obterLabelVinculoNexo(v),
                  value: v.id
                }))}
                values={vinculosSelecionadosNexoIndividual}
                onChange={(item, checked) => { relacionarVinculoAoNexo(item, checked, tiposNexoTecnico.INDIVIDUAL.id) }}
                erro={validacao?.erroVinculoNexoIndividualObrigatorio}
                disabled={isSomenteLeitura || listaVinculosNexoIndividual?.length === 1}
              />

              <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea
                    id="fundamentacaoNexoIndividual"
                    label="* Fundamentação Técnica"
                    value={fundamentacaoNexoIndividual}
                    onChange={(value) => props.alterarFundamentacaoNexoIndividual(value)}
                    maxLength={QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caractRestantesFundNexoIndividual}`}
                    erro={validacao?.erroFundamentacaoNexoIndividualObrigatoria}
                    disabled={isCampoFundamentacaoNexoIndividualDesabilitado}
                  />
                </Grid>
                    {
                      !isCampoFundamentacaoNexoIndividualDesabilitado &&
                      <Grid item >
                        <ButtonSelecionarItem
                          id="selecionarItemDicionarioFundamentacaoNexoIndividual"
                          onSelect={props.alterarFundamentacaoNexoIndividual}
                          maxLength={caractRestantesFundNexoIndividual}
                          valorAtual={fundamentacaoNexoIndividual}/>
                      </Grid>
                    }
                </Grid>
            </>
          )
        }
        {
          exibeNexo &&
          deveExibirMensagemAlertaNexoEpidemiologico &&
          (
            <>
              <HeaderSecao texto="Caracterização do Nexo Técnico Epidemiológico" info={INFO_NEXO_EPIDEMIOLOGICO} />
              <BrMessage warning>
                Para análise do Nexo Técnico Epidemiológico, deverá ser selecionado pelo menos um vínculo na Capacidade Laborativa,
                em que o segurado se encontra incapaz
              </BrMessage>
            </>
          )
        }
        {
          exibeNexo &&
          exibeNexoEpidemiologico &&
          (
            <>
              <HeaderSecao texto="Caracterização do Nexo Técnico Epidemiológico" info={INFO_NEXO_EPIDEMIOLOGICO} />
              <p>
                * Diante desta identificação, avalie para cada vínculo abaixo se existem elementos que DESCARACTERIZAM a fixação do Nexo Técnico Epidemiológico.
              </p>
              <div id="tabelaVinculosNTEP" style={{ marginBottom: '10px' }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Vínculo</th>
                      <th style={{ width: '15%', padding: '0px' }} colSpan={2}>* Há elementos que<br /> descaracterizam o NTEP?</th>
                      <th>* Justificativa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      listaVinculosNexoEpidemiologico?.map(v =>
                        <tr key={v.id}>
                          <td style={{ verticalAlign: 'top' }}>
                            {v.nomeVinculo !== undefined ? v.nomeVinculo : ''}<br />
                            Filiação: {v.descricaoTipoFiliado}<br />
                            Início: {formatarDateTimeISOToDataBr(v.dataInicio)}<br />
                            Fim: {v.dataFim ? formatarDateTimeISOToDataBr(v.dataFim) : 'sem data'}<br />
                            {v.dataFim 
                              ? '' //se existe data fim, nao exibe ultima remuneracao
                              : (v.ultimaRemuneracao ? `Última Remuneração: ${v.ultimaRemuneracao}` : '') //so exibe ultima remuneracao se o valor estiver preenchido;
                            }
                          </td>
                          <td style={{ padding: '0px', paddingTop: '8px', verticalAlign: 'top' }}>
                            <ButtonCheck
                              selected={v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === 3}
                              handleChange={() => indicarNexoEpidemiologicoParaVinculo(v.id, true)} //SIM para NTEP (nao descaracteriza)
                              disabled={isSomenteLeitura} />
                          </td>
                          <td style={{ padding: '0px', paddingTop: '8px', verticalAlign: 'top' }}>
                            <ButtonXMark
                              selected={v.possuiNexoTecnico === 0}
                              handleChange={() => indicarNexoEpidemiologicoParaVinculo(v.id, false)}//NAO para NTEP (descaracteriza)
                              disabled={isSomenteLeitura} />
                          </td>
                          <td style={{ verticalAlign: 'top' }}>
                            {v.possuiNexoTecnico === 1 //existe NTEP para vinculo (nao descaracteriza)
                              && v.tipoNexoTecnico === 3 //tipo do nexo tecnico = epidemiologico
                              &&
                              <span>{JUSTIFICATIVA_CARACTERIZACAO_AUTOMATICA_NTEP}</span>
                            }
                            {v.possuiNexoTecnico === 0 //nao existe NTEP para vinculo (descaracteriza)
                              &&
                              <div id={`div_justificativa_descaracterizacao_vinculo_${v.id}`}>
                                <BrSelect id={`motivoNaoFixacaoNtep_vinculo_${v.id}`}
                                  label="Selecione um motivo para a NÃO FIXAÇÃO do NTEP:"
                                  items={opcoesMotivoNaoFixacaoNtep}
                                  value={v.descaracterizacaoNtep?.motivoNaoFixacaoNtep ? opcoesMotivoNaoFixacaoNtep.find(m => m.value === v.descaracterizacaoNtep.motivoNaoFixacaoNtep) : OPCAO_VAZIA}
                                  onChange={(value) => props.alterarMotivoNaoFixacaoNtep(v.id, value)}
                                  erro={validacao && validacao[`erroMotivoNaoFixacaoNtepObrigatorio_vinculo_${v.id}`]}
                                  disabled={isSomenteLeitura} />
                                <Grid container alignItems="center">
                                  <Grid item xs>
                                    <BrTextarea
                                      id={`fundamentacaoNaoFixacaoNtep_vinculo_${v.id}`}
                                      label="Fundamentação Técnica"
                                      value={v.descaracterizacaoNtep?.fundamentacaoNaoFixacaoNtep}
                                      onChange={(value) => props.alterarFundamentacaoNaoFixacaoNtep(v.id, value)}
                                      maxLength={QTD_MAXIMA_CARACTERES}
                                      help={`Caracteres restantes: ${listaCaracteresRestantesFundNtepDescarac[v.id]}`}
                                      erro={validacao && validacao[`erroFundamentacaoNaoFixacaoNtepObrigatoria_vinculo_${v.id}`]}
                                      disabled={isCampoFundamentacaoNaoFixacaoNtepDesabilitado}/>
                                    </Grid>
                                    {
                                      !isCampoFundamentacaoNaoFixacaoNtepDesabilitado &&
                                      <Grid item >
                                        <ButtonSelecionarItem
                                          id={`selecionarItemDicionarioFundamentacaoNaoFixacaoNtep_vinculo_${v.id}`}
                                          onSelect={props.alterarFundamentacaoNaoFixacaoNtep}
                                          onSelectProps={{id:v.id}}
                                          maxLength={listaCaracteresRestantesFundNtepDescarac[v.id]}
                                          valorAtual={v.descaracterizacaoNtep?.fundamentacaoNaoFixacaoNtep}/>
                                      </Grid>
                                    }
                                </Grid>
                              </div>
                            }
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </>
          )
        }
      </div>

    </>
  );
}

const mapStateToProps = state => {
  const { dominioPericia, isSomenteLeitura, validacao } = state.principal;
  return ({
    pericia: state.pericia,
    batimentosNexo: state.modulos.moduloNexoTecnico,
    dominioPericia,
    isSomenteLeitura,
    validacao: validacao.nexoTecnico
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarExisteNexoProfissional,
  alterarFatoresRiscoProfissionalSelecionados,
  alterarTipoDoencaNexoProfissional,
  alterarFundamentacaoNexoProfissional,
  alterarJustifNegacaoNexoProfissional,
  consultarFatoresDeRiscoCid,
  consultarBatimentoCidCnae,
  alterarExisteNexoIndividual,
  alterarTipoNexoIndividual,
  alterarFundamentacaoNexoIndividual,
  alterarExisteNexoEpidemiologico,
  alterarMotivoNaoFixacaoNtep,
  alterarFundamentacaoNaoFixacaoNtep,
  relacionarVinculoAoNexo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NexoTecnico);