import { tipoSimNao } from "../../constants";

const mensagemCampoObrigatorio = "É obrigatório marcar esta opção se a incapacidade não corresponder a nenhum dos vínculos apresentados";

export const verificarSeCamposPreenchidos = pericia => {
  const vinculos = pericia?.vinculosPericia?.filter(vinculo => vinculo.ativo);
  const incapacidadeSemVinculos = pericia?.incapacidadeSemVinculos === tipoSimNao.SIM.id;
  return (vinculos && vinculos.some(v => v.possuiIncapacidade === 1)) || incapacidadeSemVinculos;
}

export const validar = pericia => {
  const vinculosPreenchidos = verificarSeCamposPreenchidos(pericia);
  const vinculos = pericia?.vinculosPericia?.filter(vinculo => vinculo.ativo && vinculo.elegivelParaEnvioAoSibe);
  const incapacidadeSemVinculos = pericia?.incapacidadeSemVinculos === tipoSimNao.SIM.id;

  if ((vinculos?.length > 0 && !vinculosPreenchidos) && !incapacidadeSemVinculos) {
    return {
      erroVinculoIncapacidadeObrigatorio: mensagemCampoObrigatorio,
      isValida: false
    }
  } else {
    return {
      erroVinculoIncapacidadeObrigatorio: null,
      isValida: true
    }
  }
}

export const limparValidacao = () => {
  return {
    erroVinculoIncapacidadeObrigatorio: null,
    isValida: true
  }
}
